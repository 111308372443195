import React, {useState, useEffect } from 'react'
import './style2.css';
import bgone from './bg4.jpg'
// import gamebg from './gamebg01.jpg'
import mkone from './mkone.png'
import caughtsnd from './game_notification.wav'
import misssnd from './baby_monkey_laugh.wav'
import winsnd from './monkey_applause.wav'
import losesnd from './monkey_mocking_and_giggling.wav'
import resetsnd from './orchestra_transition.wav'
import startsnd from './deep_gong_gbosa.mp3'
import gamesndone from './game_music_01.mp3'
import gbosa from './gbosa.mp3'
import sorosoke from './soro_soke.mp3'

import {isMobileOnly} from 'react-device-detect';

// import splashcol from './giphy.gif'

const colors = [
    "#0575C3",
    "#00C40F",
    "red",
    "#F9F400",
    "#9F9F9F",
    "#FCCA00",
    "#01D8E5",
    "#B328EB",
    "red",
    "#0575C3",
    "#F9F400",
]

const levels = [
    { 
        no: 1,
        title: "Welcome to the jungle",
        startDelay: 1500,
        endDelay: 1200,
        scoreBoost: 75,
        bgImg: ""
    },
    { 
        no: 2,
        title: "Rough Rider",
        startDelay: 1300,
        endDelay: 1100,
        scoreBoost: 96,
        bgImg: ""
    },
    { 
        no: 3,
        title: "Crooked Path",
        startDelay: 1200,
        endDelay: 1000,
        scoreBoost: 120,
        bgImg: ""
    },
    { 
        no: 4,
        title: "Wild Life",
        startDelay: 1200,
        endDelay: 1000,
        scoreBoost: 133,
        bgImg: ""
    },
    { 
        no: 5,
        title: "Stumbling Chumps",
        startDelay: 1100,
        endDelay: 900,
        scoreBoost: 138,
        bgImg: ""
    },
    { 
        no: 6,
        title: "Stubs and Thistles",
        startDelay: 1100,
        endDelay: 900,
        scoreBoost: 144,
        bgImg: ""
    },
    { 
        no: 7,
        title: "Treasure Groove",
        startDelay: 1100,
        endDelay: 900,
        scoreBoost: 149,
        bgImg: ""        
    },
    { 
        no: 8,
        title: "The Swamp of Atlantis",
        startDelay: 1100,
        endDelay: 850,
        scoreBoost: 155,
        bgImg: ""
    },
]
 
function GameBoard() {
    // Set intial values for score and power
    const scoreBoost = 75

    // Create all state variables
    const [currentDelay, setCurrentDelay] = useState(1500)
    const [endDelay, setEndDelay] = useState(1200)
    const [powerScoreMaxValue, setPowerScoreMaxValue] = useState(350)
    const [score, setScore] = useState(0)
    const [power, setPower] = useState(powerScoreMaxValue)
    const [gameOnStatus, setGameOnStatus] = useState(false)
    const [gameStatusMessage, setGameStatusMessage] = useState('Click START to begin!')
    const [intervalId, setIntervalId] = useState(null)
    const [monkeyPos, setMonkeyPos] = useState(0)
    const [monkeyColor, setMonkeyColor] = useState('')
    const [level, setLevel] = useState(1)
    const [highScore, setHighScore] = useState(0)
    const [timeSpent, setTimeSpent] = useState(0)    
    const [scoreIncrement, setScoreIncrement] = useState(5)
    const [powerDecrement, setPowerDecrement] = useState(5)
    const [gameOver, setGameOver] = useState(false)
    const [gameEndStatus, setGameEndStatus] = useState("")
    const [showinfo, setShowinfo] = useState(false)
    const [showhowto, setShowhowto] = useState(false)
    const [showabout, setShowAbout] = useState(false)    
    
    var timer

    // Load Data from Local Storage
    useEffect(() => {
        if (localStorage.getItem('highscore')) {
            const hs = parseInt(localStorage.getItem('highscore')) || 1
            setHighScore(hs)
        }
        if (localStorage.getItem('level')) {
            const lv = parseInt(localStorage.getItem('level')) || 1
            setLevel(lv)
            console.log(lv)
            setCurrentDelay(levels[lv-1].startDelay)
            setEndDelay(levels[lv-1].endDelay)
        }
        if (localStorage.getItem('currentdelay')) {
            const cdl = parseInt(localStorage.getItem('currentdelay'))
            setCurrentDelay(cdl)
        }
        if (isMobileOnly) {
            setPowerScoreMaxValue(150)
            setPower(150)
            setScoreIncrement(15)
            setPowerDecrement(2)
        }
    }, [])

    // Process game as updates happen
    useEffect(() => {
        if (gameOnStatus) {
            if (power <= 0) {
                console.log("End Game by Power!")
                setMonkeyColor("")
                setMonkeyPos(0)
                stopGame(0)
                return () => {
                    window.clearInterval(intervalId);
                };
            }
            if (score >= powerScoreMaxValue) {
                console.log("End Game by Score!")                
                setMonkeyColor("")
                setMonkeyPos(0)                
                stopGame(1)                
                return () => {
                    window.clearInterval(intervalId);
                };
            }
        } else {
            setMonkeyColor("")
            setMonkeyPos(0)
            document.getElementById('game-main-div').focus()
        }
    }, [power, score, intervalId, gameOnStatus, currentDelay, powerScoreMaxValue, timer]);

    useEffect(() => {        
        if ((score * scoreBoost) > highScore) {            
            localStorage.setItem('highscore', score * scoreBoost)
            // console.log("ending...")
        }
        if (gameOver && timer) {
            window.clearInterval(timer)
        }
    })

    useEffect(() => {
        if (gameEndStatus === "won") {            
            if (currentDelay > endDelay) {
                localStorage.setItem('currentdelay', currentDelay - 100)
            }
            keepMyScore()            
            if (level < 8) {
                // console.log("ending level...")
                // setLevel(level + 1)
                // localStorage.setItem("level", level + 1)
            }
        }
    }, [gameEndStatus])

    // Start game either from user presing S or Button click
    function startGame(e) {
        setGameOver(false)
        playAudio(5)       
        document.getElementById('game-main-div').focus() 
        // playAudio(7)
        setTimeout(function(){
            
            playAudio(6)
            setGameOnStatus(true)
            setGameStatusMessage('Game On!')
            timer = window.setInterval(gameTimerCounter, currentDelay)
            setIntervalId(timer)
        },2000)
    }

    // Handle when user clickc color with mouse
    function handlePressColor(e) {
        const selColor = e.currentTarget.textContent
        if (selColor === monkeyColor) {
            caughtAMonkey()
        } else {
            // playAudio(1)
        }
    }

    // Stop game function. Happens on user win or power levels exhausted
    function stopGame(st) {
        console.log("END")
        setGameOver(true)
        setGameOnStatus(false)
        if (st === 0) {
            playAudio(3)
            setGameStatusMessage('You Lost!')
            setGameEndStatus("lost")
        } else {
            playAudio(2)
            setGameStatusMessage('You Won!')
            setGameEndStatus("won")
        }        
        // Call function to store game data
        keepMyScore() 
        stopAudio(6)       
    }

    // Function for timer launched on start game, run every 1 second
    function gameTimerCounter() {     
        setPower(prev => prev - powerDecrement)
        const randNo = Math.floor((Math.random() * 11) + 1)
        setMonkeyPos(randNo)
        setMonkeyColor(colors[randNo])
        setTimeSpent(prev => prev + 1)
    }

    /* Restart game. Clears timer and prepares
    game for restart */
    function restartGame() {
        if (gameOnStatus) {
            if (window.confirm("Do you really want to restart current game?")) {
                setGameOver(false)
                setGameOnStatus(false)
                playAudio(4)
                try {
                    window.clearInterval(intervalId);
                } catch {}
                setScore(0)
                setPower(powerScoreMaxValue)            
                setMonkeyColor("")
                setMonkeyPos(0)
                setGameStatusMessage('Click START to begin...')   
                stopAudio(6)
            }            
        } else {
            setGameEndStatus("")
            setGameOver(false)
            setGameOnStatus(false)
            playAudio(4)
            try {
                window.clearInterval(intervalId);
            } catch {}
            setScore(0)
            setPower(powerScoreMaxValue)            
            setMonkeyColor("")
            setMonkeyPos(0)
            setGameStatusMessage('Click START to begin...')   
            stopAudio(6)
        }             
    }

    /* Reset a running gameOnStatus. Clears everything and prepares
    game for restart */
    function resetGame() {
        setGameOver(false)
        if (window.confirm("Do you really want to reset game?")) {
            setGameOnStatus(false)
            playAudio(4)
            try {
                window.clearInterval(intervalId);
                console.log(intervalId)
            } catch {}
            setScore(0)
            setPower(powerScoreMaxValue)            
            setMonkeyColor("")
            setMonkeyPos(0)
            setGameStatusMessage('Click START to begin...')       
            stopAudio(6)         
        } else {}
    }

    /* Handle and process user key input for game.
    Also includes key to start and reset game */
    function handleKeyPress(e) {
        if (e.ctrlKey && e.key === 'x') {
            resetGame()
        }

        const kk = e.key
        let selColor = "None"
        if (kk === 'r') selColor = "red"
        if (kk === 'b') selColor = "#0575C3"
        if (kk === 'g') selColor = "#00C40F"
        if (kk === 'y') selColor = "#F9F400"
        if (kk === 'e') selColor = "#9F9F9F"
        if (kk === 'o') selColor = "#FCCA00"
        if (kk === 'l') selColor = "#01D8E5"
        if (kk === 'p') selColor = "#B328EB"

        if (gameOnStatus) {
            if (selColor === monkeyColor) {
                caughtAMonkey()
            } else {
                // playAudio(1)
            }
        } else {
            if (kk === "s") startGame()
        }
    }

    /* Function to run when user cathes a monkeyColor. That is,
    when user matches ,monkey color or CaretPosition, depending
    on the game being played */
    function caughtAMonkey() {
        try {
            if (score < powerScoreMaxValue) setScore(prev => prev + scoreIncrement)
            playAudio(0)
            // playAudio(8)
        } catch(e) {}
    }

    // Store game data to local storage
    function keepMyScore() {
        localStorage.setItem('lastscore', score)        
        localStorage.setItem('level', level)
        localStorage.setItem('lastduration', timeSpent)
    }

    // Function to play audio sound
    function playAudio(pos) {
        const audioEl = document.getElementsByClassName("audio-element")[pos]
        audioEl.play()
    }

    // Function to stop audio sound
    function stopAudio(pos) {
        const audioEl = document.getElementsByClassName("audio-element")[pos]
        audioEl.pause()
    }

    function showGameInfo() {
        setShowinfo(true)
    }

    function showGameHowto() {
        setShowhowto(true)
    }

    function showAbout() {
        setShowAbout(true)
    }

    function closeGameEndOverlay() {
        setGameOver(false)
        restartGame()
    }

    function closeInfoOverlay() {
        setShowinfo(false)
    }

    function closeHowtoOverlay() {
        setShowhowto(false)
    }

    function closeAboutOverlay() {
        setShowAbout(false)
    }

    function catchTheMonkey(pos) {
        if (gameOnStatus) {
            if (monkeyPos === pos) {
                caughtAMonkey()   
            }
        }
    }

    return (
        <div id="game-main-div" className="main-container noselect" style={{backgroundImage: `url(${bgone})`, backgroundSize: "cover"}} onKeyDown={handleKeyPress} tabIndex="0">
            <div className="gameboard-shadow">
                <div className="score-power-div">
                    <div id="score-bar" className="score-bar-box">
                        <div className="score-progress-box" style={{width: score + 1, textAlign: "center"}}>
                            {score * scoreBoost}
                        </div>
                    </div>
                    <div className="power-bar-box">
                        <div className="power-progress-box" style={{width: power}}>
                            {power}
                        </div>
                    </div>
                </div>
                <div className="game-grid">
                    <div onClick={() => catchTheMonkey(1)} className="game-grid-boxes" style={{backgroundColor: `${monkeyPos === 1 ? monkeyColor : ""}`}}><img src={monkeyPos === 1 ? mkone : ""} alt="" /></div>
                    <div onClick={() => catchTheMonkey(2)}  className="game-grid-boxes" style={{backgroundColor: `${monkeyPos === 2 ? monkeyColor : ""}`}}><img src={monkeyPos === 2 ? mkone : ""} alt="" /></div>
                    <div onClick={() => catchTheMonkey(3)}  className="game-grid-boxes" style={{backgroundColor: `${monkeyPos === 3 ? monkeyColor : ""}`}}><img src={monkeyPos === 3 ? mkone : ""} alt="" /></div>
                    <div onClick={() => catchTheMonkey(4)}  className="game-grid-boxes" style={{backgroundColor: `${monkeyPos === 4 ? monkeyColor : ""}`}}><img src={monkeyPos === 4 ? mkone : ""} alt="" /></div>
                    <div onClick={() => catchTheMonkey(5)}  className="game-grid-boxes" style={{backgroundColor: `${monkeyPos === 5 ? monkeyColor : ""}`}}><img src={monkeyPos === 5 ? mkone : ""} alt="" /></div>
                    <div onClick={() => catchTheMonkey(6)}  className="game-grid-boxes" style={{backgroundColor: `${monkeyPos === 6 ? monkeyColor : ""}`}}><img src={monkeyPos === 6 ? mkone : ""} alt="" /></div>
                    <div onClick={() => catchTheMonkey(7)}  className="game-grid-boxes" style={{backgroundColor: `${monkeyPos === 7 ? monkeyColor : ""}`}}><img src={monkeyPos === 7 ? mkone : ""} alt="" /></div>
                    <div onClick={() => catchTheMonkey(8)}  className="game-grid-boxes" style={{backgroundColor: `${monkeyPos === 8 ? monkeyColor : ""}`}}><img src={monkeyPos === 8 ? mkone : ""} alt="" /></div>
                    <div onClick={() => catchTheMonkey(9)}  className="game-grid-boxes" style={{backgroundColor: `${monkeyPos === 9 ? monkeyColor : ""}`}}><img src={monkeyPos === 9 ? mkone : ""} alt="" /></div>
                    <div onClick={() => catchTheMonkey(10)}  className="game-grid-boxes" style={{backgroundColor: `${monkeyPos === 10 ? monkeyColor : ""}`}}><img src={monkeyPos === 10 ? mkone : ""} alt="" /></div>
                    <div onClick={() => catchTheMonkey(11)}  className="game-grid-boxes" style={{backgroundColor: `${monkeyPos === 11 ? monkeyColor : ""}`}}><img src={monkeyPos === 11 ? mkone : ""} alt="" /></div>
                    <div onClick={() => catchTheMonkey(12)}  className="game-grid-boxes" style={{backgroundColor: `${monkeyPos === 12 ? monkeyColor : ""}`}}><img src={monkeyPos === 12 ? mkone : ""} alt="" /></div>
                    <div onClick={() => catchTheMonkey(13)}  className="game-grid-boxes" style={{backgroundColor: `${monkeyPos === 13 ? monkeyColor : ""}`}}><img src={monkeyPos === 12 ? mkone : ""} alt="" /></div>
                    <div onClick={() => catchTheMonkey(14)}  className="game-grid-boxes" style={{backgroundColor: `${monkeyPos === 14 ? monkeyColor : ""}`}}><img src={monkeyPos === 12 ? mkone : ""} alt="" /></div>
                    <div onClick={() => catchTheMonkey(15)}  className="game-grid-boxes" style={{backgroundColor: `${monkeyPos === 15 ? monkeyColor : ""}`}}><img src={monkeyPos === 12 ? mkone : ""} alt="" /></div>
                    <div onClick={() => catchTheMonkey(16)}  className="game-grid-boxes" style={{backgroundColor: `${monkeyPos === 16 ? monkeyColor : ""}`}}><img src={monkeyPos === 12 ? mkone : ""} alt="" /></div>
                </div>
                <div className="color-boxes">
                    <div onClick={handlePressColor} className="color-box" style={{backgroundColor: "red", color: "red", fontSize: 2}}>red</div>
                    <div onClick={handlePressColor} className="color-box" style={{backgroundColor: "#0575C3", color: "#0575C3", fontSize: 2}}>#0575C3</div>
                    <div onClick={handlePressColor} className="color-box" style={{backgroundColor: "#00C40F", color: "#00C40F", fontSize: 2}}>#00C40F</div>
                    <div onClick={handlePressColor} className="color-box" style={{backgroundColor: "#F9F400", color: "#F9F400", fontSize: 2}}>#F9F400</div>
                    <div onClick={handlePressColor} className="color-box" style={{backgroundColor: "#9F9F9F", color: "#9F9F9F", fontSize: 2}}>#9F9F9F</div>
                    <div onClick={handlePressColor} className="color-box" style={{backgroundColor: "#FCCA00", color: "#FCCA00", fontSize: 2}}>#FCCA00</div>
                    <div onClick={handlePressColor} className="color-box" style={{backgroundColor: "#01D8E5", color: "#01D8E5", fontSize: 2}}>#01D8E5</div>
                    <div onClick={handlePressColor} className="color-box" style={{backgroundColor: "#B328EB", color: "#B328EB", fontSize: 2}}>#B328EB</div>
                </div>
                {/* <div className="info-bar">
                    <div className="info-box">
                        Level: <span>{level}</span>
                    </div>
                    <div className="info-box">My Score: <span style={{color: "red"}}>{score * scoreBoost}</span></div>
                    <div className="info-box">High Score: <span>{highScore}</span></div>
                    <div className="info-box">Time Spent: <span>{timeSpent} secs</span></div>
                </div> */}
                <div className="controls-box">
                    <button style={{ display:`${gameOnStatus ? "none" : ""}` }} className="control-button game-style-butt-two" onClick={startGame}><u>S</u>tart</button>
                    <button style={{ display:`${gameOnStatus ? "" : "none"}` }} className="control-button game-style-butt-two" onClick={restartGame}>Restart</button>
                    <button className="control-button game-style-butt-two" onClick={resetGame}>Rese<u>t</u></button>
                    <button className="control-button game-style-butt-two" onClick={showGameInfo}>Info</button>
                    <button className="control-button game-style-butt-two" onClick={showGameHowto}>How To</button>
                    <button className="control-button game-style-butt-two" onClick={showAbout}>About</button><br />                    
                    <audio className="audio-element">
                        <source src={caughtsnd}></source>
                    </audio>
                    <audio className="audio-element">
                        <source src={misssnd}></source>
                    </audio>
                    <audio className="audio-element">
                        <source src={winsnd}></source>
                    </audio>
                    <audio className="audio-element">
                        <source src={losesnd}></source>
                    </audio>
                    <audio className="audio-element">
                        <source src={resetsnd}></source>
                    </audio>
                    <audio className="audio-element">
                        <source src={startsnd}></source>
                    </audio>
                    <audio className="audio-element" loop>
                        <source src={gamesndone}></source>
                    </audio>
                    <audio className="audio-element">
                        <source src={gbosa}></source>
                    </audio>
                    <audio className="audio-element">
                        <source src={sorosoke}></source>
                    </audio>
                </div>
                <button className="control-button to-next-line" style={{color: `${power <= 0 ? "red" : "black"}`, fontWeight: "bold", fontSize: 15}}>{(power <= 0 ? 'Game Over!' : gameStatusMessage)}</button>
            </div>
            <div id="overlay" style={{display: `${gameOver ? "block": "none"}`}}>
                <div id="text">
                    <h1>{gameEndStatus === "won" ? "Congratulations!" : "Game Over!"}</h1><br />
                    <h1 id="text2">{gameStatusMessage}</h1><br />
                    <button id="game-over-butt" onClick={() => closeGameEndOverlay()}>Restart Game</button>
                </div>
            </div>
            <div id="overlay-info" style={{display: `${showinfo ? "block": "none"}`}}>
                <div id="text3" style={{backgroundColor: "#fff", color: "#000", width: "30vw"}}>                
                    <h5>Level</h5>                        
                    <p>{level}</p>
                    <hr style={{border: "solid 1px #eee", maxWidth: "60%", margin: "2px auto"}} />
                    <h5>My Score</h5>
                    <h4 style={{color: "red"}}>{score * scoreBoost}</h4>
                    <hr style={{border: "solid 1px #eee", maxWidth: "60%", margin: "2px auto"}} />
                    <h5>High Score</h5>
                    <p>{highScore}</p>
                    <h5>Time Spent</h5>
                    <p>{timeSpent} secs</p>
                    <button className="game-style-butt" onClick={closeInfoOverlay}> Ok </button>
                </div>
            </div>
            <div id="overlay-howto" style={{display: `${showhowto ? "block": "none"}`}}>
                <div id="text3">
                    <h3 style={{color: "#fff"}}>How to play</h3><hr />
                    <p>There are two modes of the game.<br />
                        One mode is to match the colors.<br />
                        For Desktops, you can use the keyboard keys as below to match the colors and
                        score points.<br />
                        For mobile browsers, you can just punch the color on your screen. You will need to tap 
                        very fast to gain more scores<br />

                        <h3 style={{color: "#fff"}}>The second mode is called CATCH THE monkey</h3>
                        In this mode, you can just punch the monkey directly on the screen and score points<br />

                        The power/time bar depletes every second and if you do not meet the expected score 
                        before the bar hits 0, you loose. <br /><br />
                        The game comes with a lot of intrigues which you will discover as you play<hr />
                        <p style={{backgroundColor: "#fff", color: "#000", padding: "10px 20px", borderRadius: 20}}>Keys: Start: S | Reset: ctrl-x | Red: R | Blue: B | Green: G | Purple: P | Yellow: Y | Lightblue: L | Orange: O | Black: A | Grey: E</p>
                        <hr />
                    </p>
                    <div><button className="game-style-butt" onClick={closeHowtoOverlay}> Ok </button></div>
                    <br /><br />
                </div>
            </div>
            <div id="overlay-about" style={{display: `${showabout ? "block": "none"}`}}>
                <div id="text3">
                    <h3 style={{color: "#fff"}}>Credits</h3><hr />
                    <p>Game Written by <span style={{color:"#fff"}}>Edwin Okugbo</span></p>
                    <p>With lots of credit to my daughter, <span style={{color:"pink"}}>Onella</span>, for being 
                    by my side and inspiring me to do this! And to my lovely wife, <span style={{color:"pink"}}>Rebecca</span>, for asking that I make it 
                    easy for her to win. LOL</p>
                    <div><button className="game-style-butt" onClick={closeAboutOverlay}> Ok </button></div>
                    <br /><br />
                </div>
            </div>
        </div>
    )
}

export default GameBoard
