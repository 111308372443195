// import logo from './logo.svg';
// import './App.css';
// import Main from './Main';
import GameBoard from './GameBoard';

function App() {
  return (
    <div className="App">
      {/* <div><p>Keys: Start: S | Reset: T | Red: R | Blue: B | Green: G | Purple: P | Yellow: Y | Lightblue: L | Orange: O | Black: A | Grey: E</p></div> */}
      {/* <Main /> */}
      <GameBoard />
    </div>
  );
}

export default App;
